import 'modules/polyfills/other/location_origin';
import addListener from 'plugins/utilities/add_listener';
import browserLocale from 'plugins/utilities/browser_locale';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import removeListener from 'plugins/utilities/remove_listener';
import toParamsQueryString from 'plugins/utilities/to_params_query_string';
import urlParam from 'plugins/utilities/url_param';
import viewportHeight from 'plugins/utilities/viewport_height';

import slidesLiveEmbedHost from './slideslive_embed_host';
import SlidesLiveEmbedInitializer from './slideslive_embed_initializer';

export default class SlidesLiveEmbed {
  constructor(embedContainerId, options) {
    this.container = document.getElementById(embedContainerId);
    if (!isVariableDefinedNotNull(this.container)) {
      console.warn(`SlidesLive Embed: loading failed! Container #${embedContainerId} not found.`);
      return;
    }

    this.windowMessageEventListener = null;
    this.windowResizeEventListener = null;

    this.props = {
      userUuid: undefined,
      disableAutoLoad: false,
      state: 'none',
      currentTime: 0,
      duration: 0,
      volume: null,
      muted: null,
      availablePlaybackRates: null,
      playbackRate: null,
      availableSubtitles: null,
      activeSubtitles: null,
      zoomRatio: null,
      size: null,
    };

    this.loadOptions(embedContainerId, options);
    this.loadCallbacks(options);

    SlidesLiveEmbedInitializer.load(this.options.host, (userUuid, embedInitToken, { disableAutoLoad = false } = {}) =>
      this.load(userUuid, embedInitToken, { disableAutoLoad }),
    );
  }

  load(userUuid, embedInitToken, { disableAutoLoad = false } = {}) {
    this.props.userUuid = userUuid;
    this.props.embedInitToken = embedInitToken;
    this.props.disableAutoLoad = disableAutoLoad;

    this.addListeners();
    this.createHtml();
  }

  destroy() {
    this.removeListeners();

    this.iframe = undefined;
    this.container.innerHTML = '';
  }

  loadOptions(embedContainerId, options) {
    this.options = {
      embedContainerId,
      presentationId: parseInt(options.presentationId || 0, 10),
      autoLoad: isVariableDefinedNotNull(options.autoLoad) ? !!options.autoLoad : true,
      autoPlay: !!options.autoPlay,
      startTime: options.startTime,
      startSlide: options.startSlide,
      hideZoomControls: isVariableDefinedNotNull(options.hideZoomControls) ? !!options.hideZoomControls : null,
      zoomRatio: parseFloat(options.zoomRatio),
      zoomHandleColor: options.zoomHandleColor,
      zoomSliderColor: options.zoomSliderColor,
      disableFullscreen: !!options.disableFullscreen,
      host: slidesLiveEmbedHost(options.host),
      locale: options.locale || browserLocale({ defaultLocale: 'en' }),
      live: !!options.live,
      account: options.account || '',
      folder: options.folder || '',
      liveAutoLoad: isVariableDefinedNotNull(options.liveAutoLoad) ? !!options.liveAutoLoad : true,
      width: isVariableDefinedNotNull(options.width) ? parseInt(options.width, 10) : null,
      verticalEnabled: isVariableDefinedNotNull(options.verticalEnabled) ? !!options.verticalEnabled : false,
      verticalEnabledOnMobile: isVariableDefinedNotNull(options.verticalEnabledOnMobile)
        ? !!options.verticalEnabledOnMobile
        : false,
      verticalDisabledWhenWidthGte: isVariableDefinedNotNull(options.verticalDisabledWhenWidthGte)
        ? parseInt(options.verticalDisabledWhenWidthGte, 10)
        : null,
      verticalWhenWidthLte: isVariableDefinedNotNull(options.verticalWhenWidthLte)
        ? parseInt(options.verticalWhenWidthLte, 10)
        : null,
      allowHiddenControlsWhenPaused: isVariableDefinedNotNull(options.allowHiddenControlsWhenPaused)
        ? !!options.allowHiddenControlsWhenPaused
        : false,
      registrationId: isVariableDefinedNotNull(options.registrationId) ? options.registrationId : null,
      defaultStreamQuality: options.defaultStreamQuality,
      debugSlidesLivePlayer: !!options.debugSlidesLivePlayer,

      registrationIdParamName: isVariableDefinedNotNull(options.registrationIdParamName)
        ? options.registrationIdParamName
        : 'slregid',
      fitToViewport: isVariableDefinedNotNull(options.fitToViewport) ? options.fitToViewport : true,
      debugCallbacks: !!options.debugCallbacks,
      maxHeight: isVariableDefinedNotNull(options.maxHeight) ? parseInt(options.maxHeight, 10) : null,
      customUserId: options.customUserId || '',
    };

    this.loadOptionsFromUrlParams();

    if (this.options.width <= 0) {
      this.options.width = null;
    }
  }

  loadOptionsFromUrlParams() {
    if (
      !isVariableDefinedNotNull(this.options.registrationId) &&
      isVariableDefinedNotNull(this.options.registrationIdParamName)
    ) {
      const registrationId = urlParam(this.options.registrationIdParamName);
      if (isVariableDefinedNotNull(registrationId)) {
        this.options.registrationId = registrationId;
      }
    }

    if (!this.options.debugSlidesLivePlayer) {
      const debugSlidesLivePlayer = urlParam('debug_slideslive_player');
      this.options.debugSlidesLivePlayer = debugSlidesLivePlayer && debugSlidesLivePlayer !== 'false';
    }
  }

  addCallback(name, callback) {
    if (!isVariableDefinedNotNull(this.callbacks[name])) {
      this.callbacks[name] = [];
    }

    this.callbacks[name].push(callback);
  }

  loadCallback(options, optionsParam, callbackName) {
    if (typeof options[optionsParam] === 'function') {
      this.addCallback(callbackName, options[optionsParam]);
    }
  }

  loadCallbacks(options) {
    this.callbacks = {};

    this.loadCallback(options, 'onLoad', 'load');
    this.loadCallback(options, 'onError', 'error');
    this.loadCallback(options, 'onStateChanged', 'stateChanged');
    this.loadCallback(options, 'onVolumeChanged', 'volumeChanged');
    this.loadCallback(options, 'onAvailablePlaybackRatesChanged', 'availablePlaybackRatesChanged');
    this.loadCallback(options, 'onPlaybackRateChanged', 'playbackRateChanged');
    this.loadCallback(options, 'onAvailableSubtitlesChanged', 'availableSubtitlesChanged');
    this.loadCallback(options, 'onActiveSubtitlesChanged', 'activeSubtitlesChanged');
    this.loadCallback(options, 'onSizeChanged', 'sizeChanged');
    this.loadCallback(options, 'onSlideUrl', 'slideUrl');
    this.loadCallback(options, 'onTimeUpdate', 'timeUpdate');

    if (!isVariableDefinedNotNull(this.callbacks.error)) {
      this.addCallback('error', (error) => {
        const timeout = Math.round(Math.random() * 20000 + 10000);

        if (error === 'no_live_event') {
          // eslint-disable-next-line no-console
          console.info('SlidesLive Embed: livestream not ready. Reload in', timeout, 'ms.');
        } else {
          console.warn('SlidesLive Embed: loading failed.', error);
        }

        if (this.options.liveAutoLoad) {
          setTimeout(() => this.reload(), timeout);
        }
      });
    }
  }

  addListeners() {
    let messageEvent = 'message';
    if (!isVariableDefinedNotNull(window.addEventListener)) {
      messageEvent = 'onmessage';
    }

    if (!this.windowMessageEventListener) {
      this.windowMessageEventListener = addListener(window, messageEvent, (event) => this.handleMessage(event));
    }

    if (this.options.fitToViewport && !this.windowResizeEventListener) {
      this.windowResizeEventListener = addListener(window, 'resize', () => {
        if (!this.isFullscreenActive) {
          this.updateSize();
        }
      });
    }
  }

  removeListeners() {
    if (this.windowMessageEventListener) {
      removeListener(window, { id: this.windowMessageEventListener });
      this.windowMessageEventListener = null;
    }

    if (this.windowResizeEventListener) {
      removeListener(window, { id: this.windowResizeEventListener });
      this.windowResizeEventListener = null;
    }
  }

  createIframe() {
    const sandboxAttributes = [
      'allow-forms',
      'allow-pointer-lock',
      'allow-popups',
      'allow-same-origin',
      'allow-scripts',
      'allow-top-navigation',
    ];

    if (typeof document.requestStorageAccess === 'function') {
      sandboxAttributes.push('allow-storage-access-by-user-activation');
    }

    const iframe = document.createElement('iframe');

    iframe.style.margin = '0 auto';
    iframe.style.display = 'block';
    iframe.style.width = '100%';

    iframe.setAttribute('src', this.embedUrl);
    iframe.setAttribute('height', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('frameBorder', '0');
    iframe.setAttribute('sandbox', sandboxAttributes.join(' '));
    iframe.setAttribute('allow', 'autoplay; fullscreen');

    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('webkitallowfullscreen', '');
    iframe.setAttribute('mozallowfullscreen', '');

    return iframe;
  }

  createHtml() {
    this.iframe = this.createIframe();
    this.container.style.width = '100%';
    this.container.appendChild(this.iframe);
  }

  handleMessage(event) {
    if (event.origin !== '*' && event.origin !== this.options.host) {
      return;
    }

    let data = event.data;
    if (typeof data === 'string') {
      if (data.trim() === '') {
        data = {};
      } else {
        data = JSON.parse(data.replace('/*framebus*/', ''));
      }
    }

    if (!data.slideslive || data.targetId !== this.options.embedContainerId) {
      return;
    }

    switch (data.method) {
      case 'setSize':
        this.setSize(data.height);
        break;
      case 'updateSizeRequest':
        this.updateSize();
        break;
      case 'ready':
        this.updateSize();
        break;
      case 'beforeLoad':
        this.updateSize();
        break;
      case 'load':
        this.updateSize();
        this.runCallback('load');
        break;
      case 'error':
        this.runCallback('error', data.name);
        break;
      case 'stateChanged':
        this.props.state = data.state;
        this.runCallback('stateChanged', data.state);
        break;
      case 'volumeChanged':
        this.props.volume = data.volume;
        this.props.muted = data.muted;
        this.runCallback('volumeChanged', data.volume, data.muted);
        break;
      case 'availablePlaybackRatesChanged':
        this.props.availablePlaybackRates = data.rates;
        this.runCallback('availablePlaybackRatesChanged', data.rates);
        break;
      case 'playbackRateChanged':
        this.props.playbackRate = data.rate;
        this.runCallback('playbackRateChanged', data.rate);
        break;
      case 'availableSubtitlesChanged':
        this.props.availableSubtitles = data.tracks;
        this.runCallback('availableSubtitlesChanged', data.tracks);
        break;
      case 'activeSubtitlesChanged':
        this.props.activeSubtitles = data.track;
        this.runCallback('activeSubtitlesChanged', data.track);
        break;
      case 'zoomRatioChanged':
        this.props.zoomRatio = data.ratio;
        this.runCallback('zoomRatioChanged', data.ratio);
        break;
      case 'sizeChanged':
        this.props.size = data.size;
        this.runCallback('sizeChanged', data.size);
        break;
      case 'timeUpdate':
        this.props.currentTime = data.currentTime;
        this.props.duration = data.duration;
        this.runCallback('timeUpdate', this.props.currentTime, this.props.duration);
        break;
      case 'slideUrl':
        this.runCallback('slideUrl', data.slide, data.url);
        break;
      default:
        console.warn('SlidesLive Embed: unknown method.', data);
        break;
    }
  }

  runCallback(callbackName, ...params) {
    if (isVariableDefinedNotNull(this.callbacks[callbackName])) {
      for (const callback of this.callbacks[callbackName]) {
        callback(...params);
      }
    }
  }

  sendMessage(method, data) {
    let message = data || {};

    message.method = method;
    message.slideslive = true;

    if (isVariableDefinedNotNull(window.ActiveXObject)) {
      message = JSON.stringify(message);
    }

    if (!this.iframe.contentWindow) {
      console.warn(
        'SlidesLive Embed: iframe content window is null. Message not sent. If you want to destroy the embed make sure to call .destroy() method.',
        message,
      );
    } else if (this.iframe.contentWindow === window) {
      console.warn('SlidesLive Embed: iframe content window is main window. Message not sent.', message);
    } else {
      try {
        this.iframe.contentWindow.postMessage(message, this.options.host);
      } catch (e) {
        console.warn(message);
        console.warn(e);
      }
    }
  }

  updateSize() {
    if (!this.options.fitToViewport) return;

    const height = this.options.maxHeight ? Math.min(this.options.maxHeight, viewportHeight()) : viewportHeight();
    this.sendMessage('setAvailableSize', { height });
  }

  setSize(height) {
    if (this.isFullscreenActive) {
      height += 80;
    }

    this.iframe.height = height;
  }

  reload() {
    this.iframe = undefined;
    this.container.innerHTML = '';

    this.addListeners();
    this.createHtml();
  }

  // public playback control api

  play() {
    this.sendMessage('play');
  }

  pause() {
    this.sendMessage('pause');
  }

  toggle() {
    this.sendMessage('toggle');
  }

  seekTo(time) {
    this.sendMessage('seekTo', { time });
  }

  seekToLivePosition() {
    this.sendMessage('seekToLivePosition');
  }

  seekToSlide(slide) {
    this.sendMessage('seekToSlide', { slide });
  }

  prevSlide() {
    this.sendMessage('prevSlide');
  }

  nextSlide() {
    this.sendMessage('nextSlide');
  }

  syncVideoToSlide() {
    this.sendMessage('syncVideoToSlide');
  }

  syncSlideToVideo() {
    this.sendMessage('syncSlideToVideo');
  }

  setVolume(volume) {
    this.sendMessage('setVolume', { volume });
  }

  setMuted(muted) {
    this.sendMessage('setMuted', { muted });
  }

  setPlaybackRate(rate) {
    this.sendMessage('setPlaybackRate', { rate });
  }

  setZoomRatio(ratio) {
    this.sendMessage('setZoomRatio', { ratio });
  }

  // public playback state api

  isPlaying() {
    return this.props.state === 'playing';
  }

  isPaused() {
    return this.props.state === 'paused';
  }

  volume() {
    return this.props.volume;
  }

  muted() {
    return this.props.muted;
  }

  availablePlaybackRates() {
    return this.props.availablePlaybackRates;
  }

  playbackRate() {
    return this.props.playbackRate;
  }

  availableSubtitles() {
    return this.props.availableSubtitles;
  }

  activeSubtitles() {
    return this.props.activeSubtitles;
  }

  zoomRatio() {
    return this.props.zoomRatio;
  }

  size() {
    return this.props.size;
  }

  currentTime() {
    return this.props.currentTime;
  }

  duration() {
    return this.props.duration;
  }

  // private API

  getSlideUrl(slide) {
    this.sendMessage('getSlideUrl', { slide });
  }

  get embedUrl() {
    let autoLoad;
    if (this.props.disableAutoLoad) {
      autoLoad = false;
    } else {
      autoLoad = this.options.autoLoad;
    }

    const params = {
      js_embed_version: 3,
      embed_init_token: this.props.embedInitToken,
      embed_parent_url: window.location.href,
      embed_origin: window.location.origin,
      embed_container_id: this.options.embedContainerId,
      auto_load: autoLoad,
      auto_play: this.options.autoPlay,
      hide_zoom_controls: this.options.hideZoomControls,
      zoom_handle_color: this.options.zoomHandleColor,
      zoom_slider_color: this.options.zoomSliderColor,
      zoom_ratio: this.options.zoomRatio,
      start_time: this.options.startTime,
      start_slide: this.options.startSlide,
      disable_fullscreen: this.options.disableFullscreen,
      locale: this.options.locale,
      vertical_enabled: this.options.verticalEnabled,
      vertical_enabled_on_mobile: this.options.verticalEnabledOnMobile,
      vertical_disabled_when_width_gte: this.options.verticalDisabledWhenWidthGte,
      vertical_when_width_lte: this.options.verticalWhenWidthLte,
      allow_hidden_controls_when_paused: this.options.allowHiddenControlsWhenPaused,
      registration_id: this.options.registrationId,
      default_stream_quality: this.options.defaultStreamQuality,
      debug_slideslive_player: this.options.debugSlidesLivePlayer,
      fit_to_viewport: this.options.fitToViewport,
      custom_user_id: this.options.customUserId,
    };

    if (isVariableDefinedNotNull(this.props.userUuid)) {
      params.user_uuid = this.props.userUuid;
    }

    let path;
    if (this.options.live) {
      if (isVariableDefinedNotNull(this.options.folder) && this.options.folder !== '') {
        path = `/embed/live/${this.options.account}/${this.options.folder}`;
      } else {
        path = `/embed/live/${this.options.account}`;
      }
    } else {
      path = `/embed/presentation/${this.options.presentationId}`;
    }

    return `${this.options.host}${path}?${toParamsQueryString(params)}`;
  }

  get isFullscreenActive() {
    return (
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      false
    );
  }
}
