// eslint-disable-next-line max-len
import RecordingOrderFormBaseComponent from 'marketing/old_order/form_base_component/form_base_component';
import defer from 'plugins/utilities/defer';

export default class extends RecordingOrderFormBaseComponent {
  static get targets() {
    return [...super.targets, 'locationCountry', 'locationId', 'recordingType', 'coupon', 'recordingTypeSelect'];
  }

  connect() {
    super.connect();

    defer(this.updatePrices.bind(this));
    defer(this.propagateProductType.bind(this));
  }

  updateProductType() {
    super.updateProductType();
    this.updatePrices(false);
    this.propagateProductType();
  }

  updateRecordingType({ detail: { selected } }) {
    this.recordingTypeTarget.value = selected;
  }

  updateStart() {
    super.updateStart();
    this.update();
  }

  updateEnd() {
    super.updateEnd();
    this.update();
  }

  updatePrices(propagateUpdate = true) {
    super.updatePrices();

    if (!propagateUpdate) {
      return;
    }

    this.propagatePrices();
  }

  propagateProductType() {
    const data = this.data;
    this.recordingTypeSelectController.updateTypesByProductType(
      this.productType,
      this.orderType,
      this.region(data),
      data,
    );
  }

  propagatePrices() {
    const data = this.data;
    this.recordingTypeSelectController.updatePrices(this.region(data).currency, data.prices);
  }

  get locationCountry() {
    return this.locationCountryTarget.value;
  }

  get locationId() {
    return this.locationIdTarget.value;
  }

  get coupon() {
    if (!this.hasCouponTarget) {
      return null;
    }

    return this.couponTarget.value;
  }

  get recordingTypeSelectController() {
    return this.findControllerByName('marketing--old-order--show--recording-type-select', {
      useQuerySelector: false,
      element: this.recordingTypeSelectTarget,
    });
  }
}
