import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get outlets() {
    return ['marketing--home--showreel-modal'];
  }

  openShowreel() {
    if (!this.hasMarketingHomeShowreelModalOutlet) return;

    this.marketingHomeShowreelModalOutlet.open();
  }
}
