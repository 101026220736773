import ApplicationController from 'modules/application_controller';
import defer from 'plugins/utilities/defer';
import pushDataLayer from 'plugins/utilities/push_data_layer';
import VimeoPlayerApiLoader from 'vimeo-player-api-loader';

export default class extends ApplicationController {
  static get targets() {
    return ['video'];
  }

  initialize() {
    this.props = {
      player: null,
    };
  }

  connect() {
    defer(() => {
      this.initVideo();
      this.initOpenStateFromHash();
    });
  }

  disconnect() {
    if (!this.player) {
      return;
    }

    this.player.unload();
    this.player = null;
  }

  initVideo() {
    if (this.player) {
      return;
    }

    VimeoPlayerApiLoader.load((Vimeo) => {
      let firstPlay = true;
      let videoId = null;
      let currentTime = 0;
      let duration = 0;
      let percent = 0;
      let state = 'paused';
      const trackedPercents = { 10: false, 25: false, 50: false, 75: false, 90: false };

      const pushDataLayerFromPlayer = ({ event }) => {
        pushDataLayer({
          event,
          video_current_time: currentTime,
          video_duration: duration,
          video_percent: percent,
          video_provider: 'Vimeo',
          video_title: videoId,
          video_url: window.location.href,
          visible: true,
          'gtm.videoCurrentTime': currentTime,
          'gtm.videoDuration': duration,
          'gtm.videoPercent': percent,
          'gtm.videoProvider': 'Vimeo',
          'gtm.videoStatus': state,
          'gtm.videoTitle': videoId,
          'gtm.videoUrl': window.location.href,
          'gtm.videoVisible': true,
        });
      };

      this.player = new Vimeo.Player(this.videoTarget);

      this.player.on('play', () => {
        state = 'playing';

        if (firstPlay) {
          firstPlay = false;

          pushDataLayerFromPlayer({ event: 'video_start' });
        }

        pushDataLayerFromPlayer({ event: 'video_play' });
      });

      this.player.on('pause', () => {
        state = 'paused';

        pushDataLayerFromPlayer({ event: 'video_pause' });
      });

      this.player.on('ended', () => pushDataLayerFromPlayer({ event: 'video_complete' }));

      this.player.on('loaded', (data) => {
        videoId = data.id;

        pushDataLayerFromPlayer({ event: 'video_load' });
      });

      this.player.on('timeupdate', (data) => {
        currentTime = data.seconds;
        duration = data.duration;
        percent = Math.round(data.percent * 100);

        const percentToTrack = Math.max(...Object.keys(trackedPercents).filter((p) => p < percent));
        if (Number.isFinite(percentToTrack) && !trackedPercents[percentToTrack] && state === 'playing') {
          trackedPercents[percentToTrack] = true;
          pushDataLayerFromPlayer({ event: 'video_progress' });
        }
      });
    });
  }

  initOpenStateFromHash() {
    if (window.location.hash === '#showreel') {
      this.open();
    }
  }

  open() {
    window.history.replaceState(window.history.state, '', '#showreel');

    this.dispatchOnWindow('open', { prefix: 'modal', detail: { name: 'showreel' } });
  }

  beforeHide() {
    this.player.pause();

    window.history.replaceState(window.history.state, '', '#');
  }

  get player() {
    return this.props.player;
  }

  set player(value) {
    this.props.player = value;
  }
}
