class Timer {
  constructor(delay, callback, options = {}) {
    this.delay = delay;
    this.callback = callback;
    this.options = options;
    this.state = 'initialized';

    this.timeoutId = null;
    this.startTime = null;
    this.remainingTime = this.delay;
  }

  start() {
    this.state = 'started';

    this.startTime = Date.now();
    this.remainingTime = this.delay;

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.callback();

      if (this.options.repeat) {
        this.start();
      }
    }, this.remainingTime);
  }

  stop() {
    this.state = 'stopped';

    clearTimeout(this.timeoutId);
  }

  pause() {
    this.state = 'paused';

    clearTimeout(this.timeoutId);

    this.remainingTime -= Date.now() - this.startTime;
  }

  resume() {
    this.state = 'started';
    this.startTime = Date.now();

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.callback();

      if (this.options.repeat) {
        this.start();
      }
    }, this.remainingTime);
  }

  toggle() {
    if (this.state === 'started') {
      this.pause();
    } else {
      this.resume();
    }
  }
}

export default Timer;
