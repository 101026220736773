import ApplicationController from 'modules/application_controller';
import Money from 'modules/money';

export default class extends ApplicationController {
  static get targets() {
    return [
      'basicTitle',
      'basicPrice',
      'basicLink',
      'premiumTitle',
      'premiumPrice',
      'premiumLink',
      'enterpriseTitle',
      'enterprisePrice',
      'enterpriseLink',
      'box',
    ];
  }

  updateTypesByProductType(productType, orderType, region, data) {
    const prices = data.prices.surgePrices ? data.prices.surgePrices : data.prices;
    const tracks = productType === 'virtual' ? data.tracks[productType][orderType] : data.tracks[productType];

    this.setDefaultActive();

    this.updateTexts(tracks);
    this.updatePrices(region.currency, prices);
    this.updateRatesheetLinks(productType, orderType, region);
  }

  updatePrices(currency, prices) {
    const basic = new Money(prices.basic, currency);
    const premium = new Money(prices.premium, currency);
    const enterprise = new Money(prices.enterprise, currency);

    this.basicPriceTarget.textContent = basic.format(false, gon.locale);
    this.premiumPriceTarget.textContent = premium.format(false, gon.locale);
    this.enterprisePriceTarget.textContent = enterprise.format(false, gon.locale);
  }

  updateTexts(tracks) {
    this.basicTitleTarget.textContent = tracks.basic.name[gon.locale];
    this.premiumTitleTarget.textContent = tracks.premium.name[gon.locale];
    this.enterpriseTitleTarget.textContent = tracks.enterprise.name[gon.locale];
  }

  updateRatesheetLinks(productType, orderType, region) {
    const paperSize = gon.locale === 'en' ? 'letter' : 'a4';
    const allTypes = ['recording', 'livestream', 'virtual_conference', 'virtual_studio', 'highlight_video'];
    const type = productType === 'virtual' ? orderType : productType;

    let link = this.ratesheetLinkTemplate;
    link = link.replace('[REGION]', region.id).replace('[PAPER_SIZE]', paperSize);

    for (const t of allTypes) {
      const show = type === t;
      link += `&${t}=${show ? 1 : 0}`;
    }

    this.basicLinkTarget.href = link;
    this.premiumLinkTarget.href = link;
    this.enterpriseLinkTarget.href = link;
  }

  setDefaultActive() {
    if (!this.selectedRecordingType) {
      const boxIndex = Math.floor(this.boxTargets.length / 2);
      const type = this.boxTargets[boxIndex].dataset.type;

      this.setActive(type);

      return;
    }

    for (const box of this.boxTargets) {
      box.classList.toggle('active', box.dataset.type === this.selectedRecordingType);
    }
  }

  setActiveFromEvent(event) {
    const type = event.currentTarget.dataset.type;

    this.setActive(type);
  }

  setActive(type) {
    this.element.dataset.selected = type;

    for (const box of this.boxTargets) {
      box.classList.toggle('active', type === box.dataset.type);
    }

    this.element.dataset.selected = type;
    this.dispatch('change', { detail: { selected: type } });
  }

  get selectedRecordingType() {
    return this.element.dataset.selected;
  }

  get ratesheetLinkTemplate() {
    return `https://alex.slideslive.com/ratesheets?language=${gon.locale}&region=[REGION]&paper_size=[PAPER_SIZE]`;
  }
}
