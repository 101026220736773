import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['item'];
  }

  connect() {
    if (this.isTurboPreview) {
      this.selectByLocation();
    }
  }

  updateCurrentItem(event) {
    this.selectItem(event.currentTarget);
  }

  selectItem(item) {
    for (const it of this.itemTargets) {
      this.updateUnselectedItem(it);
    }

    this.updateSelectedItem(item);
    item.focus();
  }

  selectByLocation() {
    const path = `${window.location.origin}${window.location.pathname}`;

    for (const it of this.itemTargets) {
      if (it.href === path) {
        this.updateSelectedItem(it);
      } else {
        this.updateUnselectedItem(it);
      }
    }
  }

  updateUnselectedItem(item) {
    item.setAttribute('aria-current', false);
  }

  updateSelectedItem(item) {
    item.setAttribute('aria-current', true);
  }
}
