function browserLocale({ countryCodeOnly = true, defaultLocale = undefined } = {}) {
  const navigatorLocale =
    navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

  if (!navigatorLocale) {
    return defaultLocale;
  }

  return countryCodeOnly ? navigatorLocale.trim().split(/[-_]/)[0] : navigatorLocale.trim();
}

export default browserLocale;
