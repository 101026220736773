import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function slidesLiveEmbedHost(defaultHost) {
  if (isVariableDefinedNotNull(defaultHost) && defaultHost !== '') {
    return defaultHost;
  }

  return 'https://slideslive.com';
}

export default slidesLiveEmbedHost;
