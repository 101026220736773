import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get values() {
    return {
      referenceModalUrl: {
        type: String,
        default: '',
      },
    };
  }

  showReferenceModal() {
    if (!this.referenceModalUrlValue) return;

    this.dispatchOnWindow('open', {
      prefix: 'modal',
      detail: { name: 'modalV2', options: { withHistory: true, url: this.referenceModalUrlValue } },
    });

    this.referenceModalUrlValue = '';
  }
}
