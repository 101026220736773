import ApplicationController from 'modules/application_controller';
import VimeoPlayerApiLoader from 'vimeo-player-api-loader';

export default class extends ApplicationController {
  static get targets() {
    return ['video'];
  }

  initialize() {
    this.props = {
      player: null,
    };

    this.loadVideo();
  }

  disconnect() {
    this.destroyVideo();
  }

  showVideo() {
    this.videoTarget.hidden = false;

    if (this.player) this.player.play();
  }

  loadVideo() {
    if (this.player) {
      return;
    }

    VimeoPlayerApiLoader.load((Vimeo) => {
      this.player = new Vimeo.Player(this.videoTarget);

      this.player.on('error', this.handleVideoError.bind(this));
      this.player.on('play', this.handleVideoPlay.bind(this));
    });
  }

  destroyVideo() {
    if (!this.player) {
      return;
    }

    this.player
      .unload()
      .then(() => {
        this.player = null;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  handleVideoError() {
    this.videoTarget.hidden = true;
  }

  handleVideoPlay() {
    this.videoTarget.hidden = false;
  }

  get player() {
    return this.props.player;
  }

  set player(value) {
    this.props.player = value;
  }
}
